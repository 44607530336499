//将订单商品按原始订单排成二维数组
export { dealWithOrderGoods }

function dealWithOrderGoods(data, warehouseCode) {
  var tempOrderSns = []
  var tempArr = []
  var originOrders = []
  var tempGroupObj = {}
  var tempGroupObj2 = {}
  var orderGoodsNum = 0
  var totalSkuNum = 0
  var totalSkuDiscountFee = 0
  var is_group = false //是否是组合商品订单
  data.forEach(function (item) {
    //第一次遍历  将商品按原始订单分类
    if (warehouseCode) {
      item.choosewarehouseCode = warehouseCode
      item.oldChoosewarehouseCode = warehouseCode
    }
    if (item.group) {
      is_group = true
      item.skuCode = item.group.goodsGroupCode
      item.numShow = item.groupNum
      item.name = item.group.goodsGroupName
      var tempKey = item.diffKey
      if (tempKey in tempGroupObj) {
        tempGroupObj[tempKey] += 1
        tempGroupObj2[tempKey] += item.totalFee || 0
      } else {
        tempGroupObj[tempKey] = 1
        tempGroupObj2[tempKey] = item.totalFee || 0
        orderGoodsNum += item.numShow
      }
    } else {
      item.totalFee2 = item.totalFee
      item.skuCode = item.sku?.skuCode || item.skuCode
      item.numShow = item.number
      orderGoodsNum += item.numShow
    }
    //计算总的商品优惠
    totalSkuDiscountFee += item.discountFee ? item.discountFee : 0
    //计算总sku数量
    totalSkuNum += item.number
    //
    item.editable = false
    item.splitable = false
    item.price = item.price == undefined ? 0.0 : item.price.toFixed(6)
    item.discountFee = item.discountFee == undefined ? 0.0 : item.discountFee.toFixed(6)
    item.divideDiscountFee =
      item.divideDiscountFee == undefined ? 0.0 : item.divideDiscountFee.toFixed(6)
    item.taxFee = item.taxFee == undefined ? 0.0 : item.taxFee.toFixed(6)
    item.goodsFee = item.goodsFee == undefined ? 0.0 : item.goodsFee.toFixed(6)
    item.totalFee = item.totalFee == undefined ? 0.0 : item.totalFee.toFixed(6)
    item.fenxiaoTotalFee = item.fenxiaoTotalFee == undefined ? 0.0 : item.fenxiaoTotalFee.toFixed(6)
    // item.returnNum = 0;
    var platformOrderIdTemp = item.originOrder == undefined ? '' : item.originOrder.platformOrderId
    if (tempOrderSns.indexOf(platformOrderIdTemp) < 0) {
      tempOrderSns.push(platformOrderIdTemp)
      if (item.originOrder && item.originOrder.elseInfo) {
        item.originOrder.elseInfoObj = JSON.parse(item.originOrder.elseInfo)
      }
      originOrders.push(item.originOrder)
      tempArr[tempOrderSns.indexOf(platformOrderIdTemp)] = []
    }
    tempArr[tempOrderSns.indexOf(platformOrderIdTemp)].push(item)
  })
  tempArr.forEach(function (item1) {
    //第二次遍历  处理组合商品
    item1.forEach(function (item2) {
      if (item2.group) {
        if (item2.diffKey in tempGroupObj) {
          item2.rowspan = tempGroupObj[item2.diffKey]
          item2.totalFee2 = tempGroupObj2[item2.diffKey]
          item2.show = true
          delete tempGroupObj[item2.diffKey]
        } else {
          item2.show = false
        }
      } else {
        item2.rowspan = 1
        item2.show = true
        item2.totalFee2 = item2.totalFee
      }
    })
  })
  return {
    orderGoods: tempArr,
    totalSkuDiscountFee: totalSkuDiscountFee.toFixed(6),
    totalSkuNum: totalSkuNum,
    orderGoodsNum: orderGoodsNum,
    originOrderSns: tempOrderSns,
    originOrders: originOrders,
    is_group,
  }
}
